<template>
    <div id="detection" class="mainContent">
        <div id="wrapper1">
            <div id="title">
                <p class="bigFont">{{$t('detection.text12')}}</p>
                <p class="smallFont">LINE DETECTION</p>
            </div>
            <div id="detectionHolder">
                <p id="subTitle">
                    <span id="text1">{{$t('detection.text1')}}</span>
                    <span id="text2">{{$t('detection.text2')}}</span>
                </p>
                <div id="detectionDataHolder" class="pc" v-for="(item,index) in ress" :key="index" v-if="index==0">
                    <a :href="item.ip" target="_blank" class="detectionData" style="height: 50px;">
                        <span class="url" style="margin-top: 4px; margin-right: 30px;">
                            {{item.ip}}
                            <span class="time" style="float: right; margin: 4px 0px 0px;">
                                {{item.time}}
                            </span>
                            <span class="signalBar" style="float: right; margin-right: -65px;" v-if="xhs[0]">
                                <span class="bar active" v-for="item in xhs[0]" :key="index"></span>
                                <span class="bar  " v-for="item in 5-xhs[0]" :key="index"></span>
                            </span>
                        </span>
                    </a>
                    <button id="reCheck" style="height: 50px; margin: 2px auto 0px; border-radius: 9px;"
                        @click="_jc">{{$t('detection.text3')}}</button>
                </div>
                <div id="infoHolder">
                    <div id="info1">
                        <p id="subTitle">{{$t('detection.text4')}}</p>
                        <p class="text">{{$t('detection.text5')}}</p>
                        <p class="text">{{$t('detection.text6')}}</p>
                        <p class="text">{{$t('detection.text7')}}</p>
                        <p class="text">{{$t('detection.text8')}}</p>
                    </div>
                    <div id="info2">
                        <div id="qrCode" :style="`background-image: url(${info.qrCode});`"></div>
                        <div id="textButtonHolder">
                            <p id="text">
                                {{$t('detection.text9')}}{{info.siteName}}{{$t('detection.text10')}}
                                <br>
                                {{$t('detection.text11')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                times: [],
                xhs: [],
                ress: []
            };
        },
        watch: {
            times: {
                deep: true,
                handler() {
                    this.xhs = [];
                    let min = this._min(this.times);
                    let r = 1 / min;
                    this.times.forEach((item, index) => {
                        let xh = Math.round(5 / (item * r))
                        this.xhs.push(xh);
                    });
                }
            }
        },
        created() {
            this._jc();
        },
        mounted() { },
        methods: {
            _jc() {
                this.$http.get('navigation_detect').then(res => {
                    this.ress = res;
                    this.times = [];
                    res.forEach((item, index) => {
                        let time = item.time.replace('ms', '');
                        this.times.push(time);
                    });
                })
            },
            _min(arr) {
                var min = arr[0];
                var len = arr.length;
                for (var i = 1; i < len; i++) {
                    if (arr[i] < min) {
                        min = arr[i];
                    }
                }
                return min;
            },
        },
    };
</script>
<style lang="scss" scoped>
@media screen and (max-width: 468px) {
    .mainContent{
        height: auto !important;
    }
}
.mainContent{
    height: 96vh;
    min-height: 750px;
}

.bigFont,
.smallFont {
  color: #fff;
  font-weight: 100;
}
.bigFont {
//   font-size: 65px;
  position: relative;
  top: -18px;
}
.smallFont {
//   font-size: 42px;
  position: relative;
  top: -33px;
}
</style>