import axios from 'axios';
import self from '@/index.js';
import {
  Message
} from 'element-ui';
console.log(window.serverData.url)
var domain = window.serverData.url + '/api'; 
console.log(domain,'域名');
let lang = localStorage.getItem('lang')
let $http = axios.create({  
  timeout: 5000,
  withCredentials: false, // 允许携带cookie
  headers: { 
    'X-Requested-With': 'XMLHttpRequest',
    "Content-Type": "application/json",
    "language":lang
    // "Access-Control-Allow-Credentials": true,
//   'Access-Control-Allow-Origin': '*',
// 'Access-Control-Allows':['x-xsrf-token']
  },
});
// 添加请求拦截器
$http.interceptors.request.use(function (config) {
    // config.baseURL='/api/';
    // config.baseURL='https://dhhtapi.ty88vip.com/api/';
    // config.baseURL='http://hg3008-test.sport.wgle6.com/api/';
    config.baseURL=domain;
    // config.baseURL='http://hg3008-test.sport.wgle6.com/api/';
    // console.log(config);
    // if(config.url.indexOf('http')<0){
    //     config.baseURL = 'https://dhhtapi.ty88vip.com/api/';
    // }else{
    //     config.baseURL='/api/';
    // }
//  config.headers['token'] =44;
//  config.baseURL = '/api/';
  // if (!Array.isArray(options.data)) {
  //   options.data = {
  //     token: token,
  //     ...options.data
  //   }
  // }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// 添加响应拦截器
$http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
    // console.log(response);
  let res = response.data;
  if (res.msg && res.code && res.msg != '获取成功') {
    Message({
      message: res.msg,
      type: 'success'
    })
  }
  if(res.data==-1){
      location.href='/?show_login=1';
  }
  if (res.code == 0 && res.msg) {
    Message({
      message: res.msg,
      type: 'error'
    })
  }
  return Promise.resolve(response.data);
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
export default $http
