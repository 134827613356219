<template>
  <div id="verify" class="mainContent">
    <div id="wrapper1">
      <div id="title">
        <p class="bigFont">{{$t('verify.text5')}}</p>
        <p class="smallFont">DOMAIN NAME AUTHENTICATION</p>
      </div>
      <div id="checkerHolder">
        <p id="subTitle">
          <span id="text1">{{$t('verify.text1')}}</span>
          <span id="text2">{{$t('verify.text2')}}</span>
        </p>
        <div id="checker">
          <div id="inputWrapper">
            <span id="placeHolder">http://</span>
            <input type="text" name="checkerInput" id="checkerInput" autofocus="autofocus" v-model="url">
          </div>
          <input type="submit" name="checkerButton" id="checkerButton" :value="$t('verify.text6')" @click="_check">
        </div>
        <p id="checkerStatus" class="no_input green" v-if="is_check==1">{{$t('verify.text3')}}{{info.siteName}}{{$t('verify.text4')}}</p>
        <p id="checkerStatus" class="no_input red" v-if="is_check===0">{{info.detectTips}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: '',
      is_check: '',
      info: {}
    };
  },
  created() {

  },
  mounted() {
    // window.addEventListener('scroll', this._test) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {

    _check() {
      if (!this.url) {
        return;
      }
      this.$http.get(`navigation_url?url=${this.url}`).then(res => {
        if (res.status == "true") {
          this.is_check = 1;
        } else {
          this.is_check = 0;
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.mainContent{
  height: 90vh;
}
.bigFont,
.smallFont {
  color: #fff;
  font-weight: 100;
}
.bigFont {
  // font-size: 65px;
  position: relative;
  top: -18px;
}
.smallFont {
  // font-size: 42px;
  position: relative;
  top: -33px;
}
</style>