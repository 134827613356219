import Vue from 'vue'
import Router from 'vue-router'
// 引入依赖
import autoRouter from 'vue-router-auto'

Vue.use(Router)

let routes = autoRouter({
  // 页面级的.vue存放位置，必传
  rc: require.context('@/index/pages', true, /\.vue$/),
  // '/'的重定向，可选，默认为''
//   redirect: '/test',
  // 页面级的.vue存放的文件夹，可选，默认为:views
  rootFile: 'index/pages',
})


console.log(routes);
export default new Router({
  routes
})
