import Vue from 'vue'
Vue.config.silent = true
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI, { size: 'small', zIndex: 3000 });


import axios from 'axios'; 
Vue.prototype.$axios=axios;

import mixins from './index/mixins.js'

Vue.mixin(mixins);


// Vue.prototype.$domain = 'https://www.hndlyf.cn';
Vue.prototype.$vuex = null ;

import $http from './index/http.js'
Vue.prototype.$http = $http
import App from './Index.vue'
import router from './index/router'
import store from './index/store'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// sessionStorage.setItem('lang',window.serverData.lan)
// let lang = localStorage.getItem('lang')
let lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : window.serverData.lan
const i18n = new VueI18n({
  locale: lang || 'zh_cn',    // 语言标识
  messages: {
		'zh_cn': require('@/i18n/zh_cn.json'),  
		'zh_cht': require('@/i18n/zh_cht.json'),  
		'vi_vn': require('@/i18n/vi_vn.json'),
		'en_gb': require('@/i18n/en_gb.json'),
	}
})
Vue.prototype._i18n = i18n

Vue.config.productionTip = false
  
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)
 
// import VueCanvasPoster from 'vue-canvas-poster'
// Vue.use(VueCanvasPoster)

// 自动组件
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// const requireComponent = require.context(
//   // 其组件目录的相对路径
//   // './components',
//   // 是否查询其子目录
//   false,
//   // 匹配基础组件文件名的正则表达式
//   /ls[A-Z]\w+\.(vue|js)$/
// )

// requireComponent.keys().forEach(fileName => {
//   // 获取组件配置
//   const componentConfig = requireComponent(fileName)

//   // 获取组件的 PascalCase 命名
//   const componentName = upperFirst(
//     camelCase(
//       // 获取和目录深度无关的文件名
//       fileName
//       .split('/')
//       .pop()
//       .replace(/\.\w+$/, '')
//     )
//   )

//   // 全局注册组件
//   Vue.component(
//     componentName,
//     // 如果这个组件选项是通过 `export default` 导出的，
//     // 那么就会优先使用 `.default`，
//     // 否则回退到使用模块的根。
//     componentConfig.default || componentConfig
//   )
// })
// 自动组件


var app = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    render: h => h(App)
  })
  
  export default app