<template>
    <div id="admin">
        <div id="left" style="width:200px;">
            <el-scrollbar style="height: 100%; overflow-x: hidden;background-color: #3C444D;">
                <el-menu router unique-opened background-color="#3C444D" text-color="#fff" :default-active="$route.path"
                    active-text-color="#409EFF">
                    <div v-if="is_localhost">
                        <el-menu-item index="/table">
                            <i class="el-icon-menu"></i>
                            <span slot="title">{{$t('admin.text1')}}</span>
                        </el-menu-item>
                        <el-menu-item index="/menus">
                            <i class="el-icon-menu"></i>
                            <span slot="title">{{$t('admin.text2')}}</span>
                        </el-menu-item>
                    </div>
                    <el-submenu v-for="(item,index) in menus" :key="index" :index="item.id+''">
                        <span slot="title"><i :class="item.icon"></i>{{item.title}}
                        </span>
                        <el-submenu v-for="(item1,index1) in item.children" :key="index1" :index="item1.id+''">
                            <span slot="title"><i class="el-icon-plus"></i>{{item1.title}}</span>
                            <el-menu-item v-for="(item2,index2) in item1.children" :key="index2" :index="'/'+item2.url">
                                {{item2.title}}
                                <!-- <el-input v-model="item2.url" @change="_name_url(item2.id,item2.url)"></el-input> -->
                            </el-menu-item>
                        </el-submenu>
                    </el-submenu>
                </el-menu>
            </el-scrollbar>
        </div>
        <div id="right">
            <el-scrollbar style="height: 100%; overflow-x: hidden; width: 100%">
                <router-view :key="key"></router-view>
                <div v-if="is_localhost">
                    <el-button type="text" @click="_table_info">{{$t('admin.text3')}}</el-button>
                    <div class="flex" style="flex-wrap: wrap;">
                        <span v-for="(item, index) in table_info.fields" :key="index" class="flex"
                            style="padding: 10px;">
                            {{ item.COLUMN_NAME }}({{ item.COLUMN_COMMENT }})
                            <input v-model="item.COLUMN_COMMENT" clearable @blur="_comment(index)"
                                style="width: 50px;" />
                            <a @click="_del_field(item.COLUMN_NAME)">{{$t('admin.text4')}}</a>
                        </span>
                        <div class="flex">
                            <el-input v-model="f_name"></el-input>
                            <el-button @click="_add_field">{{$t('admin.text5')}}</el-button>
                        </div>
                    </div>
                    <el-input type="textarea" :value="table_info.form_str"></el-input>
                    <el-input type="textarea" :value="table_info.table_str"></el-input>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                f_name: '',
                table_info: {
                    fields: [],
                    form_str: "",
                    table_str: "",
                },
                menus: [],
            };
        },
        computed: {
            key() {
                return this.$route.path + new Date();
            },
            is_localhost() {
                let url = window.location.href;
                if (url.indexOf('localhost') >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        created() {
            this.table_info = this.$options.data().table_info;
            this._menus();
        },
        methods: {
            _comment(index) {
                this.$http.post('table/comment', {
                    table: this.vuex_table,
                    field: this.table_info.fields[index]['COLUMN_NAME'],
                    comment: this.table_info.fields[index]['COLUMN_COMMENT']
                }).then(res => { })
            },
            _add_field() {
                this.$http.post('table/field', {
                    table: this.vuex_table,
                    field: this.f_name
                }).then(res => {
                })
            },
            _name_url(id, url) {
                this.$http.post('admin_menus/name', {
                    id: id,
                    url: url
                })
            },
            _del_field(field) {
                this.$http.post('table/del_field', {
                    table: this.vuex_table,
                    field: field
                }).then(res => {
                    if (res.code) {
                        this._table_info();
                    }
                })
            },
            _table_info() {
                this.$http
                    .post("index/table_info", {
                        table: this.vuex_table,
                    })
                    .then((res) => {
                        console.log("数据表信息", res.data);
                        this.table_info = res.data;
                    });
            },
            _menus() {
                this.$http.post("admin_menus/index").then((res) => {
                    this.menus = res.data;
                });
            },
        },
    };
</script>
<style lang="scss" scoped>

</style>
<style lang="scss" scoped>

</style>