<template>
  <div class="shade_layer">
    <div class="notice_contain">
      <div class="logo_area" :style="`background-image: url(${cfg.startLogo});`"></div>
      <p class="url" @click="goLink()">{{$t('pageIndex.text1')}}
        <a href="javascript:void(0)" class="redi_url">{{name}}</a>
      </p>
      <!-- <router-link to="/home" class="link">
        点击继续访问
      </router-link> -->
      <p @click="goLink()" class="link">{{$t('pageIndex.text2')}}</p>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 0,
      name: '',
      data: [],
      num: 0,
      url:''
    };
  },
  mounted() {
    this.$http.post('NavigationBackup').then(res => {
      if(res.length == 0) {
          this.$router.push('/home')
          return
      }
      this.time = res.length
      this.name = res[this.num].name
      this.url = res[this.num].url
      let timer = setInterval(() => {
        this.num += 1
        if(this.num < res.length){
            this.name = res[this.num].name
            this.url = res[this.num].url
        }else{
            this.name = res[res.length - 1].name
            this.url = res[res.length - 1].url
            clearInterval(timer);
            this.$router.push('/home')
        }
      }, 1000)
    })
  },
  methods: {
      goLink(){
        this.$router.push('/home')
        // window.location.href = this.url
      }
  }
};
</script>
<style lang="scss" scoped>
.link,.url{
  cursor: pointer;
}
</style>