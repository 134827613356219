<template>
  <div id="app">
    <div id="main" :class="show_nav?'rightMove':'canScroll'">
      <header id="mainHeader">
        <div id="wrapper1">
          <div id="logo" :style="`background-image: url(${cfg.logo});`"></div>
          <div class="select">
              <div class="sel" @click="listStatus"></div>
              <div class="selList" v-show="showList">
                <ul>
                  <li v-for="(item,i) in langList" :key='i' @click="chooseLang(item.value)">{{item.name}}</li>
                </ul>
              </div>
            </div>
          <div class="nav_icon" @click="show_nav=!show_nav"></div>
          <nav id="navigation">
            <router-link to="/home">
              {{$t('index.text1')}}
            </router-link>
            <router-link to="/detection">
              {{$t('index.text2')}}
            </router-link>
            <router-link to="/verify">
              {{$t('index.text3')}}
            </router-link>

            <a :href="item.n_url" target="_blank" v-for="(item,index) in navs" :key="index">{{item.name}}</a>

          </nav>
        </div>
      </header>
      <router-view :key="key"></router-view>
      <footer id="mainFooter">
        <p id="copyright">{{info.copyright}}</p>
      </footer>
      <div id="rightNav" v-if="show_nav" @click="()=>{show_nav=false}">
        <router-link to="/home">
          {{$t('index.text1')}}
        </router-link>
        <router-link to="/detection">
          {{$t('index.text2')}}
        </router-link>
        <router-link to="/verify">
          {{$t('index.text3')}}
        </router-link>
        <a :href="item.n_url" target="_blank" v-for="(item,index) in navs" :key="index">{{item.name}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navs: [
        { n_url: '' },
        { n_url: '' },
        { n_url: '' },
        { n_url: '' },
        { n_url: '' },
      ],
      show_nav: false,
      langList: [
        { 'name': '简体中文', 'value': 'zh_cn' },
        { 'name': '繁体中文', 'value': 'zh_cht' },
        { 'name': 'Tiếng Việt', 'value': 'vi_vn' }
        //   {'name':'Englisg','value':'zh_cn'}
      ],
      showList:false
    };
  },
  computed: {
    key() {
      return this.$route.path + new Date();
    },
  },
  created() {
    this._navs();
    this._info();
  },
  methods: {
    _href(url) {
      window.open(url);//打开一个新的标签页
    },
    _navs() {
      this.$http.get('navigation_head_link').then(res => {
        this.navs = res;
      })
    },
    listStatus() {
      this.showList = !this.showList
    },
    chooseLang(value) {
      console.log(value)
      localStorage.setItem('lang', value)
      
      location.reload();
      this.showList = false
    },
  }
};
</script>
<style lang="scss">
</style>
<style lang="scss">
/* @import "@/index/assets/style.scss"; */
@import '@/index/assets/css/index.scss';
</style>
<style lang="scss" scoped>
.select {
  position: absolute;
  margin-left: 220px;
  height: 38px;
}
.sel {
  width: 160px;
  height: 38px;
  cursor: pointer;
}
.selList {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  ul {
    width: 150px;
    height: 100px;
    list-style: none;
    padding:15px;
    li {
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      font-weight: 400;
    }
  }
}
</style>