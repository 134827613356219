import {
    mapState
} from 'vuex'
import store from "@/index/store.js"
// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
let $uStoreKey = [];
try {
    $uStoreKey = store.state ? Object.keys(store.state) : [];
} catch (e) {}
let mixins = {
    metaInfo() {
        return this.meta_info || {};
    },
    data() {
        return {
            cfg: {},
            info: {}
        };
    },
    computed: {
        ...mapState($uStoreKey),
    },
    created() {
        this.$vuex = (name, value) => {
            this.$store.commit('$uStore', {
                name,
                value
            })
        };
        this._cfg();
        this._info();
    },
    methods: {
        _favicon(faviconurl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'icon';
            link.href = faviconurl;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        _cfg() {
            this.$http.post('navigation_log').then(res => {
                this.cfg = res;
                this._favicon(res.favicon);
            })
        },
        _info() {
            this.$http.post('navigation_website').then(res => {
                this.info = res;
            })
        },
    }
}
export default mixins
