<template>
    <div id="detection" class="mainContent">
        <div id="wrapper1">
            <div id="title"></div>
            <div id="detectionHolder">
                <p id="subTitle">
                    <span id="text1">线路检测</span>
                    <span id="text2">提示：ms数值越小的网址，打开速度就越快</span>
                </p>
                <div id="detectionDataHolder" class="pc">
                    <a :href="urls[0]" target="_blank" class="detectionData" style="height: 50px;">
                        <span class="url" style="margin-top: 4px; margin-right: 30px;">
                            {{urls[0]}}
                            <span class="time" style="float: right; margin: 4px 0px 0px;">{{times[0]}}ms</span>
                            <span class="signalBar" style="float: right; margin-right: -65px;" v-if="xhs[0]">
                                <span class="bar active" v-for="item in xhs[0]" :key="index"></span>
                                <span class="bar  " v-for="item in 5-xhs[0]" :key="index"></span>
                            </span>
                        </span>
                    </a>
                    <button id="reCheck" style="height: 50px; margin: 2px auto 0px; border-radius: 9px;"
                        @click="_links">重新检测</button>
                </div>
                <div id="infoHolder">
                    <div id="info1">
                        <p id="subTitle">如检测后还不能登录，请按以下操作：</p>
                        <p class="text">1 打开IE浏览器，选择：工具-&gt; Internet选项-&gt; 再选择
                            (删除历史浏览记录)-&gt; 删除-&gt; 重启IE</p>
                        <p class="text">2 如果打开本公司出现跳转到其他网站的情况，请点击查看：修复电脑DNS方法</p>
                        <p class="text">3 下载寰宇浏览器，专为线上娱乐城贵宾打造，快速度安装，安全性能佳，网络稳定</p>
                        <p class="text">4 如果以上仍不能解决您的问题，请联系在线客服</p>
                    </div>
                    <div id="info2">
                        <div id="qrCode" :style="`background-image: url(${info.qrCode});`"></div>
                        <div id="textButtonHolder">
                            <p id="text">
                                下载{{info.siteName}}客户端
                                <br>
                                再也不用记住域名了
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                urls: [],
                times: [],
                xhs: [],
                ress: []
            };
        },
        watch: {
            times: {
                deep: true,
                handler() {
                    this.xhs = [];
                    let min = this._min(this.times);
                    let r = 1 / min;
                    this.times.forEach((item, index) => {
                        let xh = Math.round(5 / (item * r))
                        this.xhs.push(xh);
                    });
                }
            }
        },
        created() {
            this._links();

            this._jc();
        },
        mounted() { },
        methods: {

            _jc() {
                this.$http.get('navigation_detect').then(res => {
                    this.ress = res.data;
                })
            },


            _min(arr) {
                var min = arr[0];
                var len = arr.length;
                for (var i = 1; i < len; i++) {
                    if (arr[i] < min) {
                        min = arr[i];
                    }
                }
                return min;
            },
            _links() {
                this.$http.get('navigation', {}).then(res => {
                    let data = res.advertising_url;
                    let _this = this;
                    data.forEach((item, index) => {
                        this.urls.push(item['n_url']);
                        let time0 = new Date().getTime();
                        $.ajax({
                            url: item['n_url'],
                            timeout: 5000,
                            complete(data) {
                                if (data.status == 200) {
                                    let time1 = new Date().getTime();
                                    let time = time1 - time0;

                                    _this.$set(_this.times, index, time);
                                } else {
                                    _this.$set(_this.times, index, 10000);
                                }
                            },
                            fail() {
                                console.log('失败');
                            }
                        })

                    });
                })
            }
        },
    };
</script>
<style lang="scss" scoped>
</style>